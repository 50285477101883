<template>
  <div class="deposit">
    <Loading v-if="isLoading" />
    <div class="loading-box" v-if="!isLoading && !isSucc">
      <div class="loading-text">加载失败，请重试</div>
    </div>
    <iframe
      v-if="!isWap"
      id="depositIframe"
      src=""
      frameborder="0"
      style="width: 100%; height: 760px"
      scrolling="no"
    ></iframe>
  </div>
</template>

<script>
import funds from "@/apis/funds.js";
import account from "@/apis/account.js";
import Loading from "@/components/loading.vue";
import config from "@/config/index.js";
export default {
  data() {
    return {
      isBind: "",
      isFirst: "",
      isLoading: true,
      isSucc: true,
    };
  },
  components: { Loading },
  computed: {
    isWap() {
      return this.$store.state.common.isWap;
    },
  },
  methods: {},
  mounted() {
    let token = localStorage.getItem("token");
    let source = 1;
    let params = {
      platform: "pc",
    };
    if (this.isWap) {
      source = 2;
      params.platform = "wap";
    }

    funds.getAllExhangeRate(params).then((res) => {
      let depositRate = "";
      if (res.IsSuccess) {
        depositRate = res.Data.dollerRmbRate; //汇率

        account.getIdentityState().then((res) => {
          if (res.IsSuccess) {
            this.isBind =
              res.Data.authenticationMethod - 0 === 2 ||
              res.Data.authenticationMethod - 0 === 1
                ? 1
                : 0;
            this.isFirst = res.Data.accountStatus == 1 ? 1 : 0;
            //let host = location.host;
            let url = config.BASE_ANTSWALLETS_URL; //"https://upay.antswallets.com";
            // if (
            //   host.indexOf("localhost") > -1 ||
            //   host.indexOf(".lan") > -1 ||
            //   host.indexOf("192.168") > -1
            // ) {
            //   url = "http://upay.antswallets.lan";
            // }
            url +=
              "/#/pay?from=jf24k&token=" +
              token +
              "&exchangeRate=" +
              depositRate +
              "&source=" +
              source +
              "&isBind=" +
              this.isBind +
              "&isFirst=" +
              this.isFirst +
              "&backUrl=" +
              location.origin;
            if (this.isWap) {
              this.isLoading = false;
              location.href = url;
            } else {
              let obj = document.getElementById("depositIframe");
              obj.setAttribute("src", url);
              if (obj.attachEvent) {
                obj.attachEvent("onload", () => {
                  this.isLoading = false;
                });
              } else {
                obj.onload = () => {
                  this.isLoading = false;
                };
              }
            }
          }
        });
      } else {
        this.isLoading = false;
        this.isSucc = false;
        this.$toast("加载失败，请重试");
      }
    });
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/funds/deposit.scss";
</style>
