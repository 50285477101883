import axiosN from "axios";
import axios from "@/axios/axios.js";
const account = {
  //图片上传
  async upImg(params) {
    const options = {
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
        token: localStorage.getItem("token"),
      },
      data: params,
      url: "apis/uc/user/userinfo/identityinfoimg",
    };
    return await axiosN(options);
  },
  async upBankImg(params) {
    const options = {
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
        token: localStorage.getItem("token"),
      },
      data: params,
      url: "apis/uc/user/bank/userbankimg",
    };
    return await axiosN(options);
  },
  async submitImg(params) {
    return await axios.post("/uc/user/userinfo/identityInfoSubmit", params, {
      des: "直客wap图片上传提交",
      sTime: new Date().getTime(),
    });
  },
  //根据银行卡号前六位获取银行名称
  async bankName(params) {
    return await axios.get(
      "/uc/bank/matchingBank",
      { params },
      {
        des: "直客wap根据银行卡号前六位获取银行名称",
        sTime: new Date().getTime(),
      }
    );
  },
  //银行卡资料新增
  async addBankCard(params) {
    return await axios.post("/uc/user/bank/add", params, {
      des: "直客wap银行卡资料新增",
      sTime: new Date().getTime(),
    });
  },
  //银行卡资料更新
  async updateBankCard(params) {
    return await axios.post("/uc/user/bank/update", params, {
      des: "直客wap银行卡资料更新",
      sTime: new Date().getTime(),
    });
  },
  //银行资料列表
  async bankList(params) {
    return await axios.post("/uc/user/bank/list", params, {
      des: "直客wap银行资料列表",
      sTime: new Date().getTime(),
    });
  },
  //银行卡详情
  async bankCardDetail(params) {
    return await axios.post("/uc/user/bank/detail", params, {
      des: "直客wap银行卡详情",
      sTime: new Date().getTime(),
    });
  },
  //四要素开关
  async bankSwitch(params) {
    return await axios.get(
      "/uc/user/bank/bankSwitch",
      { params },
      { des: "四要素开关", sTime: new Date().getTime() }
    );
  },
  //取消绑定银行卡
  async cancelBindCard(params) {
    return await axios.post("/uc/user/bank/removebind", params, {
      des: "直客wap取消绑定银行卡",
      sTime: new Date().getTime(),
    });
  },
  //用户中心发送验证码
  async sendPhoneCode(params) {
    return await axios.post("/uc/sendcode2", params, {
      des: "直客wap发送验证码",
      sTime: new Date().getTime(),
    });
  },
  //修改密码
  async changePwd(params) {
    return await axios.post("/uc/user/password/modify", params, {
      des: "直客wap改密码",
      sTime: new Date().getTime(),
    });
  },
  //持仓明细
  async userPositions(params) {
    return await axios.get(
      "/uc/api/transaction/userPositions",
      { params },
      { des: "直客wap获取持仓明细", sTime: new Date().getTime() }
    );
  },
  //平仓明细
  async userClosePositions(params) {
    return await axios.get(
      "/uc/api/transaction/userClosePositions",
      { params },
      { des: "直客wap获取平仓明细", sTime: new Date().getTime() }
    );
  },
  //获取账户资料基本信息
  async getIdentityInfo(params = "") {
    return await axios.post("/uc/user/userinfo/identityInfo", params, {
      des: "直客wap获取账户资料基本信息",
      sTime: new Date().getTime(),
    });
  },
  //实名认证
  async submitInfo(params) {
    return await axios.post("/uc/user/userinfo/identityInfoSubmitI", params, {
      des: "直客wap提交账户资料信息",
      sTime: new Date().getTime(),
    });
  },
  //认证状态
  async getIdentityState(params) {
    return await axios.get("/uc/user/userinfo/identityState", params, {
      des: "直客wap获取账户认证状态",
      sTime: new Date().getTime(),
    });
  },
  //获取资金信息
  async getAccoutMarginFree(params = {}) {
    return await axios.post("/uc/getAccoutMarginFree", params, {
      des: "直客wap获取资金信息",
      sTime: new Date().getTime(),
    });
  },
  //激活模拟账号
  async getSimulationAccount(params = {}) {
    return await axios.post("/uc/user/bindAccount/activateSimulation", params, {
      des: "直客wap激活模拟账号",
      sTime: new Date().getTime(),
    });
  },
  //绑定邮箱
  async bindEmail(params = {}) {
    return await axios.post("/uc/user/userinfo/bindEmail", params, {
      des: "直客wap绑定邮箱",
      sTime: new Date().getTime(),
    });
  },
  //发送邮箱验证码
  async sendEmailCode(params = {}) {
    return await axios.post("/uc/user/userinfo/sendEmailCode", params, {
      des: "直客wap绑定邮箱发送验证码",
      sTime: new Date().getTime(),
    });
  },
};

export default account;
