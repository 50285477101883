<template>
  <div class="main-div">
    <PageBack />
    <router-view></router-view>
  </div>
</template>

<script>
import PageBack from "@/components/pageBack.vue";
export default {
  name: "Common",
  components: {
    PageBack,
  },
  data() {
    return {};
  },
  // methods: {
  //   handleBack() {
  //     this.$router.goBack();
  //   },
  // },
};
</script>

<style lang="scss"></style>
